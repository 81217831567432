import {atom} from "recoil";

// Utils
import parseJsonSafely from "utils/parseJsonSafely";

export const isFreeTrial = atom({
    key: "isFreeTrial",
    default: null,
});

export const isYourCompanyBlocked = atom({
    key: "isYourCompanyBlocked",
    default: null,
});

export const userMe = atom({
    key: 'userMe',
    default: parseJsonSafely(localStorage.getItem('user')) || {},
});

export const companyAllUsers = atom({
    key: "companyAllUsers",
    default: {}
});

export const companyData = atom({
    key: "companyData",
    default: {}
});

export const isQuickTourActive = atom({
    key: "isQuickTourActive",
    default: false,
});

export const selectionBarData = atom({
   key: 'selectionBarData',
   default: {
       count: 0,
       onReset: () => {},
       actions: [],
   },
});

// teams and people
// TODO: if you add a new atom please make sure it resets in useResetRecoil.js

export const teamsAndPeopleActiveKey = atom({
   key: "teamsAndPeopleActiveKey",
    default: 1
});

export const peoplePagination = atom({
    key: "peoplePagination",
    default: {}
});

export const addedOrRemovedPeople = atom({
    key: "addedOrRemovedPeople",
    default: {}
});

export const editableUserData = atom({
    key: "editableUserData",
    default: null
});

export const teamsData = atom({
    key: "teamsData",
    default: {}
});

export const teamsPagination = atom({
    key: "teamsPagination",
    default: {}
});

export const editableTeam = atom({
    key: "editableTeam",
    default: {}
});

export const editedTeamData = atom({
    key: "editedTeamData",
    default: {}
});

export const editableTeamMember = atom({
    key: "editableTeamMember",
    default: null
});

export const teamNewUser = atom({
    key: "teamNewUser",
    default: null
});

export const leadersCount = atom({
    key: "leadersCount",
    default: 0
});

export const usersCount = atom({
    key: "usersCount",
    default: 0
});

export const isSelectedTeam = atom({
    key: "isSelectedTeam",
    default: false,
});

export const isActivityNeedToUpdate = atom({
    key: "isActivityNeedToUpdate",
    default: false
});

export const addedOrDeletedPostData = atom({
    key: "addedOrDeletedPostData",
    default: null
});

export const editablePostData = atom({
    key: "editablePostData",
    default: null
});

export const selectedPostsPage = atom({
    key: "selectedPostsPage",
    default: 1
});

export const selectUsersData = atom({
    key: 'selectUsersData',
    default: {
        list: [],
        page: 1,
        total: 0,
    },
});

export const selectUsersPopUpData = atom({
    key: 'selectUsersPopUpData',
    default: {
        list: [],
        page: 1,
        total: 0,
    },
});

////

//// User
/// TODO: if you add a new atom please make sure it resets in useResetRecoil.js

export const isUserFirstLoggedIn = atom({
    key: 'isUserFirstLoggedIn',
    default: null,
});

export const userReviewsData = atom({
    key: 'userReviewsData',
    default: null,
});

export const userReviewers = atom({
    key: 'userReviewers',
    default: [],
});

////

export const areTeamsAndPeopleFiltered = atom({
    key: 'areTeamsAndPeopleFiltered',
    default: {teams: false, people: false},
})

//// Check-ins
/// TODO: if you add a new atom please make sure it resets in useResetRecoil.js

export const editableCheckInData = atom({
    key: "editableCheckInData",
    default: {}
});

export const editedCheckInData = atom({
    key: "editedCheckInData",
    default: {}
});

export const meetingsData = atom({
    key: "meetingsData",
    default: null
});

export const meetingsFiltered = atom({
    key: "meetingsFiltered",
    default: false
});

export const editableCheckIn = atom({
    key: "editableCheckIn",
    default: null
});

export const canMemberAddCheckin = atom({
    key: 'canMemberAddCheckin',
    default: true,
});

export const checkinItemAssigneesList = atom({
    key: 'checkinItemAssigneesList',
    default: [],
});

export const dateIntervalCheckIn = atom({
    key: "dateIntervalCheckIn",
    default: {},
});

//// Survey
/// TODO: if you add a new atom please make sure it resets in useResetRecoil.js

export const surveyActiveTab = atom({
    key: "surveyActiveTab",
    default: {
        key: "1",
        isInsideCheck: false,
    },
});

export const surveySpecificActiveTab = atom({
    key: "surveySpecificActiveTab",
    default: {
        key: "1",
        isInsideCheck: false,
    },
});

export const surveyTeamReceivers = atom({
    key: "surveyTeamReceivers",
    default: []
});

export const surveyUsersReceivers = atom({
    key: "surveyUsersReceivers",
    default: []
});

export const surveysDataPaginated = atom({
    key: "surveysDataPaginated",
    default: null
});

export const editableSurveyData = atom({
    key: "editableSurveyData",
    default: null
});

export const surveyStatuses = atom({
    key: "surveyStatuses",
    default: null
});

export const surveyCompletionStatuses = atom({
    key: "surveyCompletionStatuses",
    default: false,
});

export const getSurveyCompletionStatuses = atom({
    key: "getSurveyCompletionStatuses",
    default: {},
});

export const surveyIsFiltered = atom({
    key: 'isFiltered',
    default: false
});

export const chosenSurveyFilter = atom({
    key: 'chosenSurveyFilter',
    default: '',
});

export const surveyPreviewStatus = atom({
    key: 'surveyPreviewStatus',
    default: null,
});

export const isSurveyAddedOrganization = atom({
    key: 'isSurveyAddedOrganization',
    default: false,
});

export const isAnonymousUser = atom({
    key: 'isAnonymousUser',
    default: false,
});

export const dateIntervalSurvey = atom({
    key: 'dateIntervalSurvey',
    default: {},
});

export const eNPSSurveyData = atom({
    key: "eNPSSurveyData",
    default: null
});

export const eNPSCurrentSurveyData = atom({
    key: "eNPSCurrentSurveyData",
    default: null
});

export const getENPSCompletionStatuses = atom({
    key: "getENPSCompletionStatuses",
    default: {},
});

export const eNPSDateInterval = atom({
    key: "eNPSDateInterval",
    default: [],
});

export const entryENPSDateInterval = atom({
    key: "entryENPSDateInterval",
    default: {},
});

export const enpsPeriodTimelineShow = atom({
    key: "enpsPeriodTimelineShow",
    default: false
});

//// Goals
/// TODO: if you add a new atom please make sure it resets in useResetRecoil.js

export const goalsData = atom({
    key: 'goalsData',
    default: {},
});

export const editableGoalData = atom({
    key: "editableGoalData",
    default: {}
});

export const duplicatedGoalData = atom({
    key: "duplicatedGoalData",
    default: {}
});

export const editedGoalData = atom({
    key: "editedGoalData",
    default: {}
})

export const goalsStatusData = atom({
    key: 'goalsStatusData',
    default: {},
});

export const goalsByStatuses = atom({
    key: 'goalsByStatuses',
    default: null,
});

export const isGoalsFiltered = atom({
    key: "isGoalsFiltered",
    default: null
});

export const specificGoalShortData = atom({
    key: "specificGoalShortData",
    default: null
});

export const specificGoalsData = atom({
    key: "specificGoalsData",
    default: {}
});

export const addedOrRemovedKeyResult = atom({
    key: "addedOrRemovedKeyResult",
    default: {}
});

export const goalStoreTimeLineData = atom({
    key: "goalStoreTimeLineData",
    default: {}
});

export const GoalCommentShortData = atom({
    key: "GoalCommentShortData",
    default: {}
});

export const GoalCommentPaginatedData = atom({
    key: "GoalCommentPaginatedData",
    default: {}
});

export const totalComments = atom({
    key: "totalComments",
    default: null
});

export const attachedFilesTotal = atom({
    key: 'attachedFilesTotal',
    default: null
});

////  Weekly Status
/// TODO: if you add a new atom please make sure it resets in useResetRecoil.js

export const weeklyStatusActiveTab = atom({
    key: "weeklyStatusActiveTab",
    default: {key: '1',
        isInsideCheck: false
    }
});

export const accomplishmentsDashboardPaginated = atom({
    key: "accomplishmentsDashboardPaginated",
    default: {}
});

export const plansDashboardPaginated = atom({
    key: "plansDashboardPaginated",
    default: {}
});

export const challengesDashboardPaginated = atom({
    key: "challengesDashboardPaginated",
    default: {}
});

export const comingUpDashboardPaginated = atom({
    key: "comingUpDashboardPaginated",
    default: {}
});

export const allWSItemsPaginated = atom({
    key: "allWSItemsPaginated",
    default: {}
});

export const dateIntervalDataWS = atom({
    key: "dateIntervalDataWS",
    default: {}
});

export const isDateIntervalLoad = atom({
    key: "isDateIntervalLoad",
    default: false
});

export const chartsData = atom({
    key: "chartsData",
    default: {}
});

export const goalsStatusWSData = atom({
    key: "goalsStatusWSData",
    default: {}
});

export const filterSortByValue = atom({
    key: "filterSortByValue",
    default: 'created_at',
});

export const filterSortingMethod = atom({
    key: "filterSortingMethod",
    default: "desc",
});

export const filterFilteredValues = atom({
   key: "filterFilteredValues",
    default: null,
});

export const isWSByUserFiltered = atom({
   key: 'isWSByUserFiltered',
    default: false,
});

//// Form templates
/// TODO: if you add a new atom please make sure it resets in useResetRecoil.js

export const formTemplatesDataPaginated = atom({
    key: "formTemplatesDataPaginated",
    default: null
});

export const formTemplatesActiveTab = atom({
    key: "formTemplatesActiveTab",
    default: {
        key: "1",
        isInsideCheck: false,
    },
});

export const editableFormTemplate = atom({
    key: "editableFormTemplate",
    default: null
});

//// Reviews
/// TODO: if you add a new atom please make sure it resets in useResetRecoil.js

export const reviewsActiveTabKey = atom({
    key: "reviewsActiveTabKey",
    default: 0
});

export const dateIntervalDataReviews = atom({
    key: "dateIntervalDataReviews",
    default: [],
});

export const entryReviewsDateInterval = atom({
    key: "entryReviewsDateInterval",
    default: {},
});

export const reviewListPaginated = atom({
    key: "reviewListPaginated",
    default: null
});

export const reviewUpcomingListPaginated = atom({
    key: "reviewUpcomingListPaginated",
    default: null
});

export const receivedReviewListPaginated = atom({
    key: "receivedReviewListPaginated",
    default: null
});

export const submittedReviewsData = atom({
    key: "submittedReviewsData",
    default: null
});

export const isFilteredMy = atom({
    key: 'isFilteredMy',
    default: false,
});

export const isFilteredReceived = atom({
    key: 'isFilteredReceived',
    default: false,
});

//// Settings
/// TODO: if you add a new atom please make sure it resets in useResetRecoil.js

export const apiToken = atom({
    key: "apiToken",
    default: null,
})

export const generalCurrentSettings = atom({
    key: "generalCurrentSettings",
    default: {}
});

export const companyLogo = atom({
   key: 'companyLogo',
   default: null,
});

export const companyLogoIsDark = atom({
    key: 'companyLogoBg',
    default: '0',
});

export const reviewPeriodSettings = atom({
    key: "reviewPeriodSettings",
    default: null
});

export const reviewGeneralSettings = atom({
    key: "reviewGeneralSettings",
    default: null
});

export const reviewPeriodTimelineShow = atom({
    key: "reviewPeriodTimelineShow",
    default: false
});

export const reviewCheckedPeriod = atom({
    key: "reviewCheckedPeriod",
    default: '',
});

export const currentlyActiveSettings = atom({
    key: "currentlyActiveSettings",
    default: null
});

export const currentlyActiveIndex = atom({
    key: "currentlyActiveIndex",
    default: {}
});

export const currentlyActiveModule = atom({
    key: "currentlyActiveModule",
    default: undefined
});

export const specificReviewUser = atom({
    key: "specificReviewUser",
    default: null,
});

export const gamificationActionData = atom({
    key: "gamificationActionData",
    default: null,
})

// My Gamification
export const gamificationDataCount = atom({
    key: "gamificationDataCount",
    default: {},
})

export const gamificationPointsData = atom({
    key: "gamificationPointsData",
    default: {},
})

export const leaderboardData = atom({
    key: "leaderboardData",
    default: {},
})

export const personalBadgeData = atom({
    key: "personalBadgeData",
    default: {},
})

export const gamificationActionsData = atom({
    key: "gamificationActionsData",
    default: null,
})

export const gamificationActionsDate = atom({
    key: "gamificationActionsDate",
    default: [],
})

export const gamificationUserData = atom({
    key: "gamificationUserData",
    default: {},
})

export const gamificationFilterResult = atom({
    key: "gamificationFilterResult",
    default: {},
})

// Custom fields
// TODO: if you add a new atom please make sure it resets in useResetRecoil.js

export const userCustomFields = atom({
    key: 'userCustomFields',
    default: [],
});

// Modules
// TODO: if you add a new atom please make sure it resets in useResetRecoil.js

export const purchasedModulesData = atom({
    key: 'purchasedModulesData',
    default: parseJsonSafely(localStorage.getItem('modules')) || null,
});

export const purchasedPricingPlan = atom({
    key: 'purchasedPricingPlan',
    default: parseJsonSafely(localStorage.getItem('pricing')) || null,
});

export const isModulesRequested = atom({
    key: 'isModulesRequested',
    default: false,
});

// Billing
export const selectedBillingMenuItem = atom({
    key: 'selectedBillingMenuItem',
    default: 0,
});

////

//// Loading middleware
/// TODO: if you add a new atom please make sure it resets in useResetRecoil.js

export const loadingMiddleware = atom({
    key: 'loadingMiddleware',
    default: false
});

////

//// Dashboard
/// TODO: if you add a new atom please make sure it resets in useResetRecoil.js

export const dateIntervalDataDashboard = atom({
    key: "dateIntervalDataDashboard",
    default: {}
});

////

export const isSidebarVisible = atom({
    key: 'isSidebarVisible',
    default: false
});

export const sidebarCollapsed = atom({
    key: 'sidebarCollapsed',
    default: !!localStorage.getItem('sidebar_collapsed') || false,
});

//// Notifications
/// TODO: if you add a new atom please make sure it resets in useResetRecoil.js

export const notificationsVisible = atom({
    key: 'notificationsVisible',
    default: false,
});

////

//// Notifications
/// TODO: if you add a new atom please make sure it resets in useResetRecoil.js

export const notificationsPageData = atom({
    key: 'notificationsPageData',
    default: null,
});

export const notificationsAllData = atom({
    key: 'notificationsAllData',
    default: [],
});

export const unreadNotificationsCount = atom({
    key: 'unreadNotificationsCount',
    default: 0,
});

////

//// Audit Trail
/// TODO: if you add a new atom please make sure it resets in useResetRecoil.js

export const auditTrailFiltered = atom({
    key: 'auditTrailFiltered',
    default: false,
});

export const auditTrailFilters = atom({
    key: 'auditTrailFilters',
    default: {
        auditTrailUserName: '',
    },
});

////

//// Socket Connections
/// TODO: if you add a new atom please make sure it resets in useResetRecoil.js

export const typingIndicators = atom({
    key: 'typingIndicators',
    default: {
        GOALS_COMMENTS: false,
    },
});

////

//// File export
/// TODO: if you add a new atom please make sure it resets in useResetRecoil.js

export const fileDownloading = atom({
    key: 'fileDownloading',
    default: false,
});

// Categories
// TODO: if you add a new atom please make sure it resets in useResetRecoil.js

export const categoriesState = atom({
    key: 'categoriesState',
    default: {},
});

// Slack Actions
// TODO: if you add a new atom please make sure it resets in useResetRecoil.js

export const actionsState = atom({
    key: 'actionsState',
    default: [],
});

// Microsoft Teams Actions
// TODO: if you add a new atom please make sure it resets in useResetRecoil.js

export const teamsActionsState = atom({
    key: 'teamsActionsState',
    default: [],
});

// Webhooks
export const webhookActiveTab = atom({
    key: "webhookActiveTab",
    default: {
        key: "1",
        isInsideCheck: false,
    },
});

// Google Calendar
// TODO: if you add a new atom please make sure it resets in useResetRecoil.js
export const googleCalendarConnectedState = atom({
    key: 'googleCalendarConnectedState',
    default: {
        connected: false,
        requested: false,
    },
});

export const microsoftOutlookCalendarConnectedState = atom({
    key: 'microsoftOutlookCalendarConnectedState',
    default: {
        connected: false,
        requested: false,
    },
});


////

// Custom Units
// TODO: if you add a new atom please make sure it resets in useResetRecoil.js
export const customUnitsData = atom({
    key: 'customUnitsData',
    default: [],
});

//

// Feeds
// TODO: if you add a new atom please make sure it resets in useResetRecoil.js
export const feedsData = atom({
    key: 'feedsData',
    default: {},
});

export const feedsDataCount = atom({
    key: 'feedsDataCount',
    default: 0,
});

export const feedsHighlightData = atom({
    key: 'feedsHighlightData',
    default: JSON.parse(localStorage.getItem('feeds_highlight') || '{}'),
});

export const specificGoalFeedsKRShortData = atom({
    key: "specificGoalFeedsKRShortData",
    default: null
});

//

// Pulse point
// TODO: if you add a new atom please make sure it resets in useResetRecoil.js

export const pulsePointPopUpData = atom({
    key: "pulsePointPopUpData",
    default: {},
});

export const pulsePointActiveTab = atom({
    key: "pulsePointActiveTab",
    default: {
        key: "1",
        isInsideCheck: false,
    }
});

export const pulsePointFilterSortByValue = atom({
    key: "pulsePointFilterSortByValue",
    default: "",
});

export const pulsePointDateInterval = atom({
    key: "pulsePointDateInterval",
    default: {},
});

export const isPPByUserFiltered = atom({
    key: 'isPPByUserFiltered',
    default: false,
});